
export default {
	name: "CoreBlockText",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isExpired() {
			const itemCheckFunction = (item) => item.type === "copy";
			return this.$store.getters["library/isExpired"](
				this.settings.contentLibraryItems,
				itemCheckFunction
			);
		},
		blockContent() {
			return this.settings?.text || null;
		},
		blockTag() {
			return this.settings?.tag || "p";
		},
	},
};
